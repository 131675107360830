import { ColumnDef, Column, formatDate, formatStringArray } from '@/components/helpers/Column';
/**
 * User userColumnsDef details
 */
enum UserColumnEnum {
  id = '_id',
  createdAt = '_createdAt',
  lastName = 'lastName',
  firstName = 'firstName',
  agency = 'agency',
  roles = 'roles',
}
const userColumnsDef: Map<String, ColumnDef> = new Map();
userColumnsDef.set(
  UserColumnEnum.id,
  new ColumnDef({
    id: UserColumnEnum.id,
    key: '_id',
    label: 'common.id',
  })
);

userColumnsDef.set(
  UserColumnEnum.createdAt,
  new ColumnDef({
    id: UserColumnEnum.createdAt,
    key: 'createdAt',
    label: 'common.created_at',
    format: formatDate,
  })
);

userColumnsDef.set(
  UserColumnEnum.lastName,
  new ColumnDef({
    id: UserColumnEnum.lastName,
    key: 'lastName',
    label: 'user.form.last_name',
  })
);
userColumnsDef.set(
  UserColumnEnum.firstName,
  new ColumnDef({
    id: UserColumnEnum.firstName,
    key: 'firstName',
    label: 'user.form.first_name',
  })
);
userColumnsDef.set(
  UserColumnEnum.agency,
  new ColumnDef({
    id: UserColumnEnum.agency,
    key: 'agency',
    label: 'user.form.agency',
  })
);
userColumnsDef.set(
  UserColumnEnum.roles,
  new ColumnDef({
    id: UserColumnEnum.roles,
    key: 'roles',
    label: 'user.form.roles',
    format: formatStringArray,
  })
);

export const userColumns = new Column(userColumnsDef);
export const userListColumns = userColumns.getColumns([
  UserColumnEnum.createdAt,
  UserColumnEnum.firstName,
  UserColumnEnum.lastName,
  UserColumnEnum.agency,
  UserColumnEnum.roles,
]);
