<template>
  <list
    :columns="columns"
    :filter="filter"
    @updateLoading="updateLoading"
    :queryGetList="queryGetList"
    :queryDeleteElement="queryDeleteElement"
    :routes="routes"
    :mutationDeleteElement="mutationDeleteElement"
    :createButtonName="createButtonName"
  />
</template>

<script>
import { defineComponent, ref } from 'vue';
import List from '@/components/list/List';
import { GET_USERS, DELETE_USER } from '@mdb/core';
import { userListColumns } from './UserColumn';

export default defineComponent({
  components: { List },
  setup(props) {
    const filter = ref({});
    const listLoading = ref(false);

    const updateLoading = value => {
      listLoading.value = value;
    };

    const updateFilter = value => {
      filter.value = value;
    };

    return {
      columns: userListColumns,
      filter: filter,
      updateFilter: updateFilter,
      listLoading: listLoading,
      updateLoading: updateLoading,
      queryGetList: GET_USERS,
      mutationDeleteElement: DELETE_USER,
      routes: { create: 'create-user', read: 'read-user', update: 'update-user' },
      createButtonName: 'user.create_user'
    };
  }
});
</script>
